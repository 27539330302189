// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_public/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_public/route.tsx");
  import.meta.hot.lastModified = "1726716723018.01";
}
// REMIX HMR END

import { Link, Outlet } from '@remix-run/react';
import { Routes } from '~/lib/constants/routes.constent';

/**
 * @description layout for unprotected page
 */
export default function PublicPageLayout() {
  return <section className="flex items-center justify-center min-h-screen mx-auto">
      <div className="flex flex-col gap-y-[103px] items-center p-4">
        <Link to={Routes.HOME}>
          <figure>
            <img src="/logo_main.svg" alt="Logo" />
          </figure>
        </Link>
        <Outlet />
        <div>
          <p className="mb-2 italic font-bold text-center">Contact Support</p>
          <p className="text-center">
            Don't have an account? Contact Support for assistance.
          </p>
        </div>
      </div>
    </section>;
}
_c = PublicPageLayout;
var _c;
$RefreshReg$(_c, "PublicPageLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;